/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * mr-eaves-xl-modern-narrow:
 *   - http://typekit.com/eulas/00000000000000003b9adbe4
 * DrukCyTT:
 *   - http://typekit.com/eulas/00000000000000003b9b4561
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */

@import url("https://assets.votenow.tv/fonts/poker-stars/poker-stars.css");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

/* active navigation links */
a.active {
  color: #D02126;
}

/* custom style overrides */
.block-blue,
.block-green,
.block-pink,
.block-red,
.block-yellow {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  line-height: 24px;
  padding: 10px 12px;
}
.headline-block {
  display: block;
  font-size: 32px;
}
.description-block {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  padding: 0 12px 10px;
}
.block-blue {
  background-color: #a8cee9;
}
.block-green {
  background-color: #4ba447;
}
.block-pink {
  background-color: #fbd1c7;
}
.block-red {
  background-color: #ee3e3b;
}
.block-yellow {
  background-color: #e4bf29;
}
.track-list {
  background-color: rgb(194, 38, 44);
  display: inline-block;
  padding: 10px;
}

@media(min-width: 768px) {
  .block-blue,
  .block-green,
  .block-pink,
  .block-red,
  .block-yellow {
    font-size: 24px;
    line-height: 28px;
    padding: 15px 20px;
  }
  .headline-block {
    font-size: 48px;
  }
  .description-block {
    font-size: 20px;
    padding: 0 20px 15px;
  }
  .track-list {
    padding: 12px 20px;
  }
}
